<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 签署设置
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="title">短信通知信息</div>
      <el-form label-width="120px">
        <el-form-item label="贡献值奖金通知">
          <p>SMS_249245482</p>
        </el-form-item>
        <el-form-item label="贡献值新增通知">
          <p>SMS_249080536</p>
        </el-form-item>
        <el-form-item label="域名">
          <p>http://contribution.3qlab.com</p>
        </el-form-item>
      </el-form>


    </div>
    <div class="container">
      <div class="title">E签宝信息</div>
      <el-descriptions>
        <el-descriptions-item label="应用ID">5111745523</el-descriptions-item>
        <el-descriptions-item label="应用描述">18100000000</el-descriptions-item>
        <el-descriptions-item label=""></el-descriptions-item>
        <el-descriptions-item label="集成平台">移动端App、支付宝小程序、其他、Web端、微信小程序、钉钉小程序</el-descriptions-item>
        <el-descriptions-item label=""></el-descriptions-item>
        <el-descriptions-item label=""></el-descriptions-item>
        <el-descriptions-item label="接入场景">通用场景:
          招投标文件、证明材料、平台入驻协议、加盟协议、验收报告、身份认证、销售合同、经销代理协议、检测报告、租赁合同、合作协议、财务单证、办公审批签字、采购合同
          物流仓储: 托运协议、代收款协议、承运协议、仓储单证、其他物流仓储场景、运输单证、ETC开卡协议
          人力资源: 其他人力资源场景、人事外包服务合同、劳动合同、人事代理服务合同、常规劳务合同、劳务派遣合同
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      form: {}
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = false
    },
    onSubmit() {

    }
  },
  components: {}
}


</script>

<style scoped lang="scss">
.handle-box {
  margin-bottom: 20px;
}

.table {
  width: 100%;
  font-size: 14px;
}

.green {
  color: #00a854;
}

.red {
  color: red;
}

.yell {
  color: yellowgreen;
}

.container {
  margin-bottom: 10px;

  .title {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .input {
    width: 400px;
  }
}
</style>
